import { getField, updateField } from 'vuex-map-fields'
import CartaPorteService from '@/services/CartaPorte'
import _cloneDeepWith from 'lodash/cloneDeepWith'
import { v4 as uuidv4 } from 'uuid'

export default {
  namespaced: true,
  state: {
    // Version JSON
    objJson: {
      factura: 1,
      complemento: {
        CartaPorte: {
          '@Version': '3.1',
          '@IdCCP': '',
          '@TranspInternac': { title: 'No', value: 'No' },
          '@EntradaSalidaMerc': {
            title: 'Entrada',
            value: 'Entrada',
          },
          '@PaisOrigenDestino': '',
          '@ViaEntradaSalida': {
            title: 'Autotransporte',
            value: '01',
          },
          '@TotalDistRec': {
            title: 'Suma de las distancias recorridas',
            value: 0,
          },
          '@RegistroISTMO': '',
          '@UbicacionPoloOrigen': '',
          '@UbicacionPoloDestino': '',
          RegimenesAduaneros: {
            RegimenAduaneroCCP: [],
          },
          Ubicaciones: {
            Ubicacion: [],
          },
          Mercancias: {
            '@PesoBrutoTotal': '',
            '@UnidadPeso': { title: 'KGM - Kilogramo', value: 'KGM' },
            '@PesoNetoTotal': '',
            '@NumTotalMercancias': '',
            '@CargoPorTasacion': '',
            '@LogisticaInversaRecoleccionDevolucion': '',
            Mercancia: [],
            Autotransporte: [],
          },
          FiguraTransporte: {
            TiposFigura: [],
          },
        },
      },
    },
  },
  mutations: {
    updateField,
    SET_COMPROBANTE(state, objJson) {
      // Si existe complemento remplaza el objeto incluyendo ID comprobante
      // De lo contrario solo carga el ID comprobante para poder guardar
      if (objJson['complemento_json']) {
        state.objJson = objJson['complemento_json']
      } else {
        state.objJson.factura = objJson['id']
      }
      state.objJson.complemento.CartaPorte['@IdCCP'] =
        'CCC' + uuidv4().slice(3).toUpperCase()
    },
    CAMPOS_CALCULADOS(state, { getters }) {
      let carta_porte = state.objJson.complemento.CartaPorte

      // Suma el total de las distancias recorridas de cada una de las ubicaciones
      carta_porte['@TotalDistRec'].value =
        carta_porte.Ubicaciones.Ubicacion.map(
          (ubicacion) => ubicacion['@DistanciaRecorrida']
        ).reduce((sum, val) => Number(sum) + Number(val), 0) || 0

      // Calcula el PesoBrutoTotal sumando todos los pesos en mercancias
      carta_porte.Mercancias['@PesoBrutoTotal'] =
        carta_porte.Mercancias.Mercancia.map(
          (mercancia) => mercancia['@PesoEnKg']
        ).reduce((sum, val) => Number(sum) + Number(val), 0) || 0

      // Cuenta los renglones de mercancias
      carta_porte.Mercancias['@NumTotalMercancias'] =
        carta_porte.Mercancias.Mercancia.length || 0
    },
    ADD_REGIMENADUANERO(state, regimen) {
      state.objJson.complemento.CartaPorte.RegimenesAduaneros.RegimenAduaneroCCP.push(
        regimen
      )
    },
    DELETE_REGIMENADUANERO(state, index) {
      state.objJson.complemento.CartaPorte.RegimenesAduaneros.RegimenAduaneroCCP.splice(
        index,
        1
      )
    },
    ADD_UBICACION(state, ubicacion) {
      state.objJson.complemento.CartaPorte.Ubicaciones.Ubicacion.push(ubicacion)
    },
    DELETE_UBICACION(state, index) {
      state.objJson.complemento.CartaPorte.Ubicaciones.Ubicacion.splice(
        index,
        1
      )
    },
    ADD_MERCANCIA(state, mercancia) {
      state.objJson.complemento.CartaPorte.Mercancias.Mercancia.push(mercancia)
    },
    DELETE_MERCANCIA(state, index) {
      state.objJson.complemento.CartaPorte.Mercancias.Mercancia.splice(index, 1)
    },
    ADD_AUTOTRANSPORTE(state, transporte) {
      state.objJson.complemento.CartaPorte.Mercancias.Autotransporte.push(
        transporte
      )
    },
    DELETE_AUTOTRANSPORTE(state, index) {
      state.objJson.complemento.CartaPorte.Mercancias.Autotransporte.splice(
        index,
        1
      )
    },
    ADD_FIGURA(state, operador) {
      state.objJson.complemento.CartaPorte.FiguraTransporte.TiposFigura.push(
        operador
      )
    },
    DELETE_FIGURA(state, index) {
      state.objJson.complemento.CartaPorte.FiguraTransporte.TiposFigura.splice(
        index,
        1
      )
    },
  },
  actions: {
    addRegimenAduanero({ commit }, regimen) {
      commit('ADD_REGIMENADUANERO', regimen)
    },
    deleteRegimenAduanero({ commit }, index) {
      commit('DELETE_REGIMENADUANERO', index)
    },
    addUbicacion({ commit }, ubicacion) {
      commit('ADD_UBICACION', ubicacion)
    },
    deleteUbicacion({ commit }, index) {
      commit('DELETE_UBICACION', index)
    },
    addMercancia({ commit }, mercancia) {
      commit('ADD_MERCANCIA', mercancia)
    },
    deleteMercancia({ commit }, index) {
      commit('DELETE_MERCANCIA', index)
    },
    addAutotransporte({ commit }, transporte) {
      commit('ADD_AUTOTRANSPORTE', transporte)
    },
    deleteAutotransporte({ commit }, index) {
      commit('DELETE_AUTOTRANSPORTE', index)
    },
    addFigura({ commit }, figura) {
      commit('ADD_FIGURA', figura)
    },
    deleteFigura({ commit }, index) {
      commit('DELETE_FIGURA', index)
    },
    fetchComprobante({ commit }, id_comprobante) {
      CartaPorteService.getComprobante(id_comprobante)
        .then((response) => {
          // Obtiene el comprobante completo
          let data = response.data
          if (data) {
            commit('SET_COMPROBANTE', data)
          }
        })
        .catch((error) => {
          // console.log(error.data)
        })
    },
    saveCartaPorte({ commit, state, getters }) {
      commit('CAMPOS_CALCULADOS', { getters })
      if (state.objJson.hasOwnProperty('id')) {
        return CartaPorteService.putCartaPorte(state.objJson.id, state.objJson)
          .then((response) => {
            // console.log('Actualizado')
          })
          .catch((error) => {
            // console.log('Error al actualizar: ' + error)
          })
      } else {
        return CartaPorteService.postCartaPorte(state.objJson)
          .then((response) => {
            // console.log('Agregado')
          })
          .catch((error) => {
            // console.log('Error al agregar: ' + error)
          })
      }
    },
  },
  getters: {
    getField,
    // TODO getAsJson funcion que no se requiere, es solo para pruebas
    getAsJson: (state) => {
      return _cloneDeepWith(state.objJson, (element) => {
        if (element && typeof element === 'object') {
          if (element.hasOwnProperty('value')) {
            return element.value
          }
          if (element.hasOwnProperty('valx')) {
            return {}
          }
        }
      })
    },
    getTransportesUtilizados: (state) => {
      let setTiposDeTransporte = new Set()
      Object.entries(
        state.objJson.complemento.CartaPorte.Ubicaciones.Ubicacion
      ).forEach(([k, v]) => {
        setTiposDeTransporte.add(v.TipoTransporte.valx)
      })
      return Array.from(setTiposDeTransporte)
    },
    getTiposDeUbicaciones: (state) => {
      let setTiposDeUbicaciones = new Array()
      Object.entries(
        state.objJson.complemento.CartaPorte.Ubicaciones.Ubicacion
      ).forEach(([k, v]) => {
        setTiposDeUbicaciones.push(v['@TipoUbicacion'].value)
      })
      return Array.from(setTiposDeUbicaciones)
    },
    countMercancias: (state) => {
      return state.objJson.complemento.CartaPorte.Mercancias.Mercancia.length
    },
    existenMaterialesPeligrosos: (state) => {
      let setMaterialesPeligrosos = new Set()
      Object.entries(
        state.objJson.complemento.CartaPorte.Mercancias.Mercancia
      ).forEach(([k, v]) => {
        setMaterialesPeligrosos.add(v['@MaterialPeligroso'].value)
      })
      return Array.from(setMaterialesPeligrosos).includes('Sí')
    },
  },
}
