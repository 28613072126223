import axiosIns from '@/libs/axios'
import axiosCatalogos from '@/libs/axiosCatalogos'

export default {
  getComprobante(id_comprobante) {
    return axiosIns.get('/api_movil/facturas_general/' + id_comprobante + '/')
  },
  postCartaPorte(complemento) {
    return axiosIns.post('/api_movil/complemento_json/', complemento)
  },
  putCartaPorte(id_comprobante, complemento) {
    return axiosIns.put(
      '/api_movil/complemento_json/' + id_comprobante + '/',
      complemento
    )
  },
  catPais(search) {
    return axiosCatalogos.get('/Pais/?search=' + search)
  },
  catCodigoPostal(search) {
    return axiosCatalogos.get('/CodigoPostal/?search=' + search)
  },
  catColonia(search) {
    return axiosCatalogos.get('/Colonia/?search=' + search)
  },
  catClaveProdServCP(search) {
    return axiosCatalogos.get('/ClaveProdServCP/?search=' + search)
  },
  catClaveProdSTCC(search) {
    return axiosCatalogos.get('/ClaveProdSTCC/?search=' + search)
  },
  catClaveUnidad(search) {
    return axiosCatalogos.get('/ClaveUnidad/?search=' + search)
  },
  catMaterialPeligroso(search) {
    return axiosCatalogos.get('/MaterialPeligroso/?search=' + search)
  },
  catTipoEmbalaje(search) {
    return axiosCatalogos.get('/TipoEmbalaje/?search=' + search)
  },
  catMoneda(search) {
    return axiosCatalogos.get('/Moneda/?search=' + search)
  },
  catFraccionArancelaria(search) {
    return axiosCatalogos.get('/FraccionArancelaria/?search=' + search)
  },
  catTipoPermiso(search) {
    return axiosCatalogos.get('/TipoPermiso/?search=' + search)
  },
  catConfigAutotransporte(search) {
    return axiosCatalogos.get('/ConfigAutotransporte/?search=' + search)
  },
  catSubTipoRem(search) {
    return axiosCatalogos.get('/SubTipoRem/?search=' + search)
  },
  catLocalidad(search) {
    return axiosCatalogos.get('/Localidad/?search=' + search)
  },
  catEstado(search) {
    return axiosCatalogos.get('/Estado/?search=' + search)
  },
  catMunicipio(search) {
    return axiosCatalogos.get('/Municipio/?search=' + search)
  },
  catRegimenAduanero(search) {
    return axiosCatalogos.get('/RegimenAduanero/?search=' + search)
  },
  catRegistroISTMO(search) {
    return axiosCatalogos.get('/RegistroISTMO/?search=' + search)
  },
  catSectorCOFEPRIS(search) {
    return axiosCatalogos.get('/SectorCOFEPRIS/?search=' + search)
  },
  catFormaFarmaceutica(search) {
    return axiosCatalogos.get('/FormaFarmaceutica/?search=' + search)
  },
  catCondicionesEspeciales(search) {
    return axiosCatalogos.get('/CondicionesEspeciales/?search=' + search)
  },
  catTipoMateria(search) {
    return axiosCatalogos.get('/TipoMateria/?search=' + search)
  },
  catDocumentoAduanero(search) {
    return axiosCatalogos.get('/DocumentoAduanero/?search=' + search)
  },
}
