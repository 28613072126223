import axios from 'axios'

const axiosCatalogos = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  // baseURL: 'https://catalogos.ecofactura.mx',
  // baseURL: 'http://127.0.0.1:3001',
  baseURL:
    process.env.NODE_ENV === 'production'
      ? 'https://catalogos02.ecofactura.mx'
      : 'https://catalogos02.ecofactura.mx',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

export default axiosCatalogos
