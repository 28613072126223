import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import { localize, ValidationProvider, ValidationObserver } from 'vee-validate'
import es from 'vee-validate/dist/locale/es.json'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/acl'

// Validation plugins
localize({
  es,
})
localize('es')
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
Vue.config.devtools = false

new Vue({
  router,
  store,
  created() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    const token = localStorage.getItem('accessToken')
    const id_comprobante = localStorage.getItem('id_comprobante')
    this.$store.commit('SET_USER_DATA', userData)
    this.$store.commit('SET_USER_TOKEN', token)
    this.$store.commit('SET_ID_COMPROBANTE', id_comprobante)
  },
  mounted() {
    // Clear the browser cache data in localStorage when closing the browser window
    // Deshabilitado temporalmente
    /*
    window.onbeforeunload = function (e) {
      let storage = window.localStorage
      storage.clear()
    }
    */
    window.onbeforeunload = function (e) {
      window.opener.postMessage('reload', '*')
    }
  },
  render: (h) => h(App),
}).$mount('#app')
