import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import CartaPorte from './CartaPorte'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    CartaPorte,
  },
  state: {
    userData: '',
    token: '',
    idComprobante: '',
    desarrollo: process.env.NODE_ENV === 'development',
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      localStorage.setItem('userData', JSON.stringify(userData))
      state.userData = userData
    },
    SET_USER_TOKEN(state, token) {
      localStorage.setItem('accessToken', token)
      state.token = token
    },
    SET_ID_COMPROBANTE(state, id_comprobante) {
      localStorage.setItem('id_comprobante', id_comprobante)
      state.idComprobante = id_comprobante
    },
  },
  actions: {
    setUserData({ commit }, userData) {
      commit('SET_USER_DATA', userData)
    },
    setUserToken({ commit }, token) {
      commit('SET_USER_TOKEN', token)
    },
    setIdComprobante({ commit }, id_comprobante) {
      commit('SET_ID_COMPROBANTE', id_comprobante)
    },
  },
  strict: false,
})
